<template>
  <v-card flat>
    <v-divider
      v-if="index !== 0 && items.length > 0"
      class="my-0"
    />
    <navigation-item
      v-for="item in items"
      :key="item.route"
      class="my-2"
      :target="item.route"
      :icon="item.icon"
      :text="item.text"
    />
  </v-card>
</template>

<script>
import NavigationItem from '@/components/app/NavigationItem'

export default {
  name: 'NavigationSection',
  components: {
    NavigationItem
  },
  props: {
    items: Array,
    index: Number
  }
}
</script>
