<template>
  <v-bottom-sheet
    v-model="shown"
    persistent
  >
    <v-sheet
      class="text-center yellow lighten-4"
      height="150px"
    >
      <v-card
        class="yellow lighten-4"
        flat
      >
        <v-btn
          class="float-right ma-5"
          color="primary"
          fab
          @click="close"
        >
          <v-icon>$vuetify.icons.close</v-icon>
        </v-btn>
        <v-card-title class="d-flex justify-center flex-grow-1 pt-10">
          {{ warningMessage }}
        </v-card-title>
      </v-card>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'WarningMessagePane',
  data: function () {
    return {
      shown: false
    }
  },
  computed: mapState({
    warningMessage: state => state.warningMessage
  }),
  watch: {
    warningMessage (value) {
      this.shown = !!value
    }
  },
  methods: {
    close () {
      this.$store.commit('setWarningMessage', '')
    }
  }
}
</script>
