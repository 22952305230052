const axios = require('axios')

const apiBaseUrl = process.env.VUE_APP_AWS_BASE_URL

const customersModule = {
  namespaced: true,
  state: {
    loaded: false,
    data: []
  },
  getters: {
    byUUID: state => uuid => state.data.find(el => el.uuid === uuid)
  },
  mutations: {
    loaded (state, value) {
      state.loaded = value
    },
    set (state, customers) {
      state.data = customers
    },
    add (state, customer) {
      state.data.unshift(customer)
    },
    modify (state, customer) {
      const index = state.data.findIndex(el => el.uuid === customer.uuid)
      if (index >= 0) {
        Object.assign(state.data[index], customer)
      } else {
        state.data.unshift(customer)
      }
    },
    delete (state, uuid) {
      const index = state.data.findIndex(el => el.uuid === uuid)
      state.data.splice(index, 1)
    }
  },
  actions: {
    async load ({ state, dispatch }) {
      if (state.data.length === 0) {
        dispatch('reload')
      }
    },
    async reload ({ commit, rootState }) {
      commit('loaded', false)
      commit('set', [])
      const customers = await rootState.dataSource2.get({ collection: 'Customers' })
      customers.reverse()
      commit('set', customers)
      commit('loaded', true)
    },
    async create ({ commit, rootState }, customer) {
      const created = await rootState.dataSource2.create({ collection: 'Customers', data: customer })
      const folderResponse = await axios.get(`${apiBaseUrl}/orgfolder/${created.uuid}`)
      created.docsFolder = folderResponse.data
      const updated = await rootState.dataSource2.update({ collection: 'Customers', data: created })
      commit('add', updated)
    },
    async update ({ commit, rootState }, customer) {
      if (!customer.docsFolder) {
        const folderResponse = await axios.get(`${apiBaseUrl}/orgfolder/${customer.uuid}`)
        customer.docsFolder = folderResponse.data
      }
      const updated = await rootState.dataSource2.update({ collection: 'Customers', data: customer })
      commit('modify', updated)
    },
    async delete ({ commit, rootState }, uuid) {
      await rootState.dataSource2.delete({ collection: 'Customers', key: uuid })
      commit('delete', uuid)
    }
  }
}

export default customersModule
