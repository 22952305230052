<template>
  <v-navigation-drawer
    v-model="drawer"
    width="200"
    app
  >
    <navigation-section
      v-for="(section, index) in accessibleDocMenu"
      :key="section.name"
      :items="section.items"
      :index="index"
    />
  </v-navigation-drawer>
</template>

<script>
import NavigationSection from '@/components/app/NavigationSection'

export default {
  name: 'NavigationDrawer',
  components: {
    NavigationSection
  },
  model: {
    prop: 'shown',
    event: 'toggle'
  },
  props: {
    shown: Boolean
  },
  data () {
    return {
      docMenu: [
        {
          name: 'home',
          items: [
            { route: '', icon: ['far', 'home'], text: 'Общее' }
          ]
        },
        {
          name: 'docs',
          items: [
            { route: 'docs', icon: ['far', 'folders'], text: 'Документы', protected: true },
            { route: 'customers', icon: ['far', 'building'], text: 'Контрагенты', protected: true }
          ]
        },
        {
          name: 'courses',
          items: [
            { route: 'schedule', icon: ['fal', 'calendar-days'], text: 'Расписание', protected: true },
            { route: 'certificates', icon: ['far', 'certificate'], text: 'Сертификаты', protected: true },
            { route: 'courses', icon: ['far', 'graduation-cap'], text: 'Шаблоны курсов', protected: true },
            { route: 'tutors', icon: ['far', 'user-graduate'], text: 'Тренеры', protected: true }
          ]
        },
        {
          name: 'admin',
          items: [
            { route: 'settings', icon: ['far', 'cogs'], text: 'Настройки', protected: true },
            { route: 'registrations', icon: ['fal', 'table-list'], text: 'Регистрации', protected: true },
            { route: 'cache', icon: ['far', 'shipping-fast'], text: 'Кеш данных', protected: true },
            { route: 'users', icon: ['far', 'users'], text: 'Пользователи', protected: true }
          ]
        },
        {
          name: 'user',
          items: [
            { route: 'logout', icon: ['far', 'sign-out'], text: 'Выход' }
          ]
        }
      ]
    }
  },
  computed: {
    drawer: {
      get () {
        return this.shown
      },
      set (value) {
        this.$emit('toggle', value)
      }
    },
    accessibleDocMenu () {
      return this.docMenu.map(section => {
        return { name: section.name, items: section.items.filter(item => !item.protected || this.canRead(item.route)) }
      })
    }
  }
}
</script>
