import Vue from 'vue'
import Vuex from 'vuex'

import { DataSource2, ClientSideCache, LambdaBasedBackend, DataSource, Fauna } from '@stqa/datasource'

import settingsModule from './store/settings'
import fragmentsModule from './store/fragments'
import emailsModule from './store/emails'
import tutorsModule from './store/tutors'
import coursesModule from './store/courses'
import courseRunsModule from './store/courseRuns'
import eventsModule from './store/events'
import submissionsModule from './store/submissions'
import groupSubmissionsModule from './store/groupSubmissions'
import customersModule from './store/customers'
import contractsModule from './store/contracts'
import registrationsModule from './store/registrations'
import usersModile from './store/users'
import lmsModile from './store/lms'
import insightsModule from './store/insights'

Vue.use(Vuex)

const CACHE_BASE_URL = process.env.VUE_APP_CACHE_BASE_URL
const CACHE_GATEWAY = process.env.VUE_APP_CACHE_GATEWAY
const API_BASE_URL = process.env.VUE_APP_AWS_BASE_URL

export default new Vuex.Store({
  state: {
    isAuthenticated: !!localStorage.getItem('sessionId'),
    user: localStorage.getItem('user'),
    faunaKey: '',
    moodleToken: '',
    dashboardId: undefined,
    dataSource: undefined,
    dataSource2: undefined,
    websocketMessage: '',
    mailSecret: '',
    warningMessage: '',
    infoMessage: '',
    overlay: false,
    overlayMessage: '',
    overlayProgress: 0,
    acl: {}
  },
  modules: {
    settings: settingsModule,
    fragments: fragmentsModule,
    emails: emailsModule,
    tutors: tutorsModule,
    courses: coursesModule,
    courseruns: courseRunsModule,
    events: eventsModule,
    submissions: submissionsModule,
    groupSubmissions: groupSubmissionsModule,
    customers: customersModule,
    contracts: contractsModule,
    registrations: registrationsModule,
    users: usersModile,
    lms: lmsModile,
    insights: insightsModule
  },
  mutations: {
    setLoggedIn (state, value) {
      console.log('setLoggedIn', value)
      state.isAuthenticated = true
      state.moodleToken = value.moodleKey
      state.mailSecret = value.mailSecret
      state.faunaKey = value.faunaKey
      state.dataSource = new DataSource({
        storage: new Fauna(value.faunaKey),
        dashboardId: state.dashboardId,
        cacheBaseUrl: CACHE_BASE_URL,
        cacheGateway: CACHE_GATEWAY,
        apiBaseUrl: API_BASE_URL
      })
      state.dataSource2 = new DataSource2({
        cache: new ClientSideCache({ cacheUrl: CACHE_BASE_URL, apiUrl: API_BASE_URL }),
        backend: new LambdaBasedBackend({ apiUrl: API_BASE_URL, dashboardId: state.dashboardId })
      })
      state.user = value
      state.acl = value
    },
    setLoggedOut (state) {
      state.isAuthenticated = false
      state.user = false
      state.moodleToken = null
      state.faunaKey = false
      state.dataSource = undefined
      state.dataSource2 = undefined
      localStorage.removeItem('user')
      localStorage.removeItem('sessionId')
    },
    setDashboardId (state, value) {
      state.dashboardId = value
    },
    setWebsocketMessage (state, value) {
      state.websocketMessage = value
    },
    setSiteCourses (state, value) {
      state.siteCourses = value
    },
    setWarningMessage (state, value) {
      state.warningMessage = value
    },
    setInfoMessage (state, value) {
      state.infoMessage = value
    },
    setOverlay (state, value) {
      if (value) {
        state.overlay = value.progress !== 100
        state.overlayMessage = value.message
        state.overlayProgress = value.progress
      } else {
        state.overlay = false
        state.overlayMessage = ''
        state.overlayProgress = 100
      }
    }
  },
  actions: {
    login ({ commit }, user) {
      commit('setLoggedIn', user)
    },
    logout ({ commit }) {
      commit('setLoggedOut')
    },
    showWarning ({ commit }, message) {
      commit('setWarningMessage', message)
    },
    showInfo ({ commit }, message) {
      commit('setInfoMessage', message)
    }
  }
})
