<template>
  <v-snackbar
    v-model="shown"
    timeout="3000"
    right
    bottom
    outlined
    color="primary"
  >
    {{ infoMessage }}
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'InfoMessagePane',
  data: function () {
    return {
      shown: false
    }
  },
  computed: mapState({
    infoMessage: state => state.infoMessage
  }),
  watch: {
    infoMessage (value) {
      this.shown = !!value
    },
    shown (value) {
      if (!value) {
        this.$store.commit('setInfoMessage', '')
      }
    }
  },
  methods: {
    close () {
      this.$store.commit('setInfoMessage', '')
    }
  }
}
</script>
