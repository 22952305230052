import Vue from 'vue'

const submissionsModule = {
  namespaced: true,
  state: {
    data: {}
  },
  mutations: {
    set (state, { courseUUID, submissions }) {
      Vue.set(state.data, courseUUID, submissions)
    },
    add (state, submission) {
      const courseUUID = submission.course
      state.data[courseUUID].push(submission)
    },
    modify (state, submission) {
      const courseUUID = submission.course
      const index = state.data[courseUUID].findIndex(el => el.uuid === submission.uuid)
      Object.assign(state.data[courseUUID][index], submission)
    },
    delete (state, submission) {
      const courseUUID = submission.course
      const index = state.data[courseUUID].findIndex(el => el.uuid === submission.uuid)
      state.data[courseUUID].splice(index, 1)
    }
  },
  getters: {
    byCourseUUID: state => courseUUID => {
      return state.data[courseUUID] || []
    }
  },
  actions: {
    async load ({ state, commit, rootState }, courseUUID) {
      if (!state.data[courseUUID]) {
        const submissions = await rootState.dataSource.getPartOf('Submissions', 'courserun', courseUUID, { cache: true })
        commit('set', { courseUUID, submissions })
      }
    },
    async create ({ commit, rootState }, submission) {
      const created = await rootState.dataSource.create('Submissions', submission, { cache: `Submissions@courserun|${submission.course}` })
      commit('add', created)
    },
    async update ({ commit, rootState }, submission) {
      const updated = await rootState.dataSource.update('Submissions', submission, { cache: `Submissions@courserun|${submission.course}` })
      commit('modify', updated)
    },
    async delete ({ commit, rootState }, submission) {
      await rootState.dataSource.delete('Submissions', submission.uuid, { cache: `Submissions@courserun|${submission.course}` })
      commit('delete', submission)
    }
  }
}

export default submissionsModule
