const emailsModule = {
  namespaced: true,
  state: {
    data: []
  },
  mutations: {
    set (state, email) {
      state.data = email
    },
    add (state, email) {
      state.data.push(email)
    },
    modify (state, email) {
      const index = state.data.findIndex(el => el.uuid === email.uuid)
      Object.assign(state.data[index], email)
    },
    delete (state, email) {
      const index = state.data.findIndex(el => el.uuid === email.uuid)
      state.data.splice(index, 1)
    }
  },
  getters: {
    byKey: state => key => {
      const found = state.data.find(el => el.key === key)
      return found
    }
  },
  actions: {
    async load ({ state, commit, rootState }) {
      if (state.data.length === 0) {
        const emails = await rootState.dataSource2.get({ collection: 'Emails' })
        commit('set', emails)
      }
    },
    async create ({ commit, rootState }, email) {
      const created = await rootState.dataSource2.create({ collection: 'Emails', data: email })
      commit('add', created)
    },
    async update ({ commit, rootState }, email) {
      const updated = await rootState.dataSource2.update({ collection: 'Emails', data: email })
      commit('modify', updated)
    },
    async delete ({ commit, rootState }, email) {
      await rootState.dataSource2.delete({ collection: 'Emails', key: email.uuid })
      commit('delete', email)
    }
  }
}

export default emailsModule
