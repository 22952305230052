import axios from 'axios'
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

const awsBaseUrl = process.env.VUE_APP_AWS_BASE_URL

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue')
    },
    {
      path: '/customers',
      name: 'customers',
      component: () => import('./views/customers/Customers.vue')
    },
    {
      path: '/customers/:uuid',
      name: 'singleCustomer',
      component: () => import('./views/customers/Customers.vue'),
      props: true
    },
    {
      path: '/customers/:uuid/:action',
      name: 'actOnCustomer',
      component: () => import('./views/customers/Customers.vue'),
      props: true
    },
    {
      path: '/docs',
      name: 'contracts',
      component: () => import('./views/Documents.vue')
    },
    {
      path: '/docs/:uuid',
      name: 'singleContract',
      component: () => import('./views/Documents.vue'),
      props: true
    },
    {
      path: '/docs/:uuid/:action',
      name: 'actOnContract',
      component: () => import('./views/Documents.vue'),
      props: true
    },
    {
      path: '/tutors',
      name: 'tutors',
      component: () => import('./views/tutors/Tutors.vue')
    },
    {
      path: '/tutors/:uuid',
      name: 'singleTutor',
      component: () => import('./views/tutors/Tutors.vue'),
      props: true
    },
    {
      path: '/tutors/:uuid/:action',
      name: 'actOnTutor',
      component: () => import('./views/tutors/Tutors.vue'),
      props: true
    },
    {
      path: '/courses',
      name: 'courses',
      component: () => import('./views/coursetemplates/CourseTemplates.vue')
    },
    {
      path: '/courses/:uuid',
      name: 'singleCourse',
      component: () => import('./views/coursetemplates/CourseTemplates.vue'),
      props: true
    },
    {
      path: '/courses/:uuid/:action',
      name: 'actOnCourse',
      component: () => import('./views/coursetemplates/CourseTemplates.vue'),
      props: true
    },
    {
      path: '/certificates',
      name: 'certificates',
      component: () => import('./views/certificates/Certificates.vue')
    },
    {
      path: '/certificates/:uuid',
      name: 'certificatesForSingleCourseRun',
      component: () => import('./views/certificates/Certificates.vue'),
      props: true
    },
    {
      path: '/certificates/:uuid',
      component: () => import('./views/certificates/Certificates.vue'),
      props: true
    },
    {
      path: '/schedule',
      name: 'courseRuns',
      component: () => import('./views/courseruns/CourseRuns.vue')
    },
    {
      path: '/schedule/:uuid',
      name: 'singleCourseRun',
      component: () => import('./views/courseruns/CourseRuns.vue'),
      props: true
    },
    {
      path: '/schedule/:uuid/:action',
      name: 'actOnCourseRun',
      component: () => import('./views/courseruns/CourseRuns.vue'),
      props: true
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('./views/Settings.vue')
    },
    {
      path: '/registrations',
      name: 'registrations',
      component: () => import('./views/Registrations.vue')
    },
    {
      path: '/cache',
      name: 'cache',
      component: () => import('./views/Cache.vue')
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('./views/users/Users.vue')
    }
  ]
})

// very basic "setup" of a global guard
router.beforeEach(async (to, from, next) => {
  if (to.path.startsWith('/login')) {
    next()
  } else if (to.path.startsWith('/auth/')) {
    const sessionId = to.path.substring('/auth/'.length)
    try {
      const { data: { user } } = await axios.get(`${awsBaseUrl}/session/${sessionId}`)
      await store.dispatch('login', user)
      localStorage.setItem('sessionId', sessionId)
      next('/')
    } catch (ex) {
      next('/login')
    }
  } else if (to.path.startsWith('/logout')) {
    const sessionId = localStorage.getItem('sessionId')
    await axios.delete(`${awsBaseUrl}/session/${sessionId}`)
    await store.dispatch('logout')
    router.push('/login')
  } else {
    const sessionId = localStorage.getItem('sessionId')
    if (!sessionId) {
      next('/login')
    } else {
      if (store.state.user) {
        next()
      } else {
        console.log('updating user info')
        try {
          const { data: { user } } = await axios.get(`${awsBaseUrl}/session/${sessionId}`)
          await store.dispatch('login', user)
          localStorage.setItem('sessionId', sessionId)
          next()
        } catch (ex) {
          next('/login')
        }
      }
    }
  }
})

export default router
