const usersModule = {
  namespaced: true,
  state: {
    data: []
  },
  mutations: {
    set (state, users) {
      state.data = users
    },
    add (state, user) {
      state.data.push(user)
    },
    modify (state, user) {
      const index = state.data.findIndex(el => el.email === user.email)
      Object.assign(state.data[index], user)
    },
    delete (state, email) {
      const index = state.data.findIndex(el => el.email === email)
      state.data.splice(index, 1)
    }
  },
  actions: {
    async load ({ state, dispatch }) {
      if (state.data.length === 0) {
        dispatch('reload')
      }
    },
    async reload ({ commit, rootState }) {
      const users = await rootState.dataSource2.get({ collection: 'Users' })
      commit('set', users)
    },
    async create ({ commit, rootState }, user) {
      const created = await rootState.dataSource2.create({ collection: 'Users', data: user })
      commit('add', created)
    },
    async update ({ commit, rootState }, user) {
      const updated = await rootState.dataSource2.update({ collection: 'Users', data: user })
      commit('modify', updated)
    },
    async delete ({ commit, rootState }, user) {
      await rootState.dataSource2.delete({ collection: 'Users', key: user.uuid })
      commit('delete', user.email)
    }
  }
}

export default usersModule
