<template>
  <v-app id="inspire">
    <template v-if="authenticated">
      <navigation-drawer
        :key="$router.currentRoute.path"
        v-model="drawer"
      />
      <app-bar
        :refresh-to-update-version="refreshToUpdateVersion"
        @toggle-drawer="drawer = !drawer"
      />

      <v-main>
        <v-container
          fluid
          class="pt-0 px-2"
        >
          <router-view />
        </v-container>
      </v-main>

      <v-footer
        color="indigo"
        app
      >
        <span class="white--text">&copy; 2019-2023</span>
      </v-footer>
    </template>
    <template v-else>
      <v-main>
        <v-container>
          <router-view />
        </v-container>
      </v-main>
    </template>
    <v-bottom-sheet
      v-model="alert"
      persistent
    >
      <v-sheet
        class="deep-orange lighten-4"
        height="200px"
      >
        <v-card
          class="deep-orange lighten-4"
          flat
        >
          <v-btn
            class="float-right ma-5"
            color="primary"
            fab
            @click="alert = !alert"
          >
            <v-icon>$vuetify.icons.close</v-icon>
          </v-btn>
          <v-card-title class="d-flex justify-center flex-grow-1">
            Возникла внутренняя ошибка, сообщите о ней Алексею Баранцеву
          </v-card-title>
          <v-card-text class="px-5 text-body-1">
            Расскажите, что вы делали, и приложите сообщение об ошибке, указанное ниже:
            <pre>{{ alertText }}</pre>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
    <warning-message-pane />
    <info-message-pane />
    <v-overlay
      :value="!!overlay"
      z-index="500"
    >
      <v-alert
        type="info"
        color="blue-grey"
      >
        {{ overlayMessage }}
        <div
          v-if="overlayProgress"
          class="text-center"
        >
          <v-progress-circular
            :value="overlayProgress"
            size="64"
          />
        </div>
      </v-alert>
    </v-overlay>
  </v-app>
</template>

<script>
import NavigationDrawer from '@/components/app/NavigationDrawer'
import AppBar from '@/components/app/AppBar'
import WarningMessagePane from '@/components/app/WarningMessagePane'
import InfoMessagePane from '@/components/app/InfoMessagePane'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    NavigationDrawer, AppBar, WarningMessagePane, InfoMessagePane
  },
  data: () => ({
    drawer: true,
    alert: false,
    alertText: '',
    version: '',
    interval: undefined,
    refreshToUpdateVersion: false
  }),
  computed: {
    authenticated () {
      return this.$store.state.isAuthenticated
    },
    overlay () {
      return this.$store.state.overlay
    },
    overlayMessage () {
      return this.$store.state.overlayMessage
    },
    overlayProgress () {
      return this.$store.state.overlayProgress
    }
  },
  async created () {
    const response = await axios.get(`${process.env.VUE_APP_BASE_URL}version.txt`)
    this.version = response.data
    this.interval = setInterval(this.checkVersion, 10 * 60 * 1000)
  },
  beforeDestroy () {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = undefined
    }
  },
  errorCaptured (err, vm, info) {
    this.unblockInteractions()
    let node = vm
    const stack = []
    do {
      stack.unshift(node.$options.name)
      node = node.$parent
    } while (node !== undefined)
    this.alertText = this.$router.currentRoute.path + '\n\n' + info + '\n\n' + stack.join('/') + '\n' + err
    this.alert = true
    this.sendEmail({
      to: ['barancev@gmail.com'],
      subject: 'В дашборде случилась ошибка',
      body: this.alertText
    })
  },
  methods: {
    async checkVersion () {
      const response = await axios.get(`${process.env.VUE_APP_BASE_URL}version.txt`)
      if (this.version !== response.data) {
        this.refreshToUpdateVersion = true
      }
    }
  }
}
</script>

<style>
.v-card__title {
  word-wrap: none;
  word-break: break-word;
}

.v-application p {
  margin-bottom: 6px;
}

.v-application .v-card__text h2 {
  margin-top: 6px;
  margin-bottom: 12px;
}

.v-btn:not(.v-btn--round).v-size--default {
  padding-top: 2px;
}

tr.clickable {
  cursor: pointer;
}
</style>
