import axios from 'axios'

const awsBaseUrl = process.env.VUE_APP_AWS_BASE_URL

const insightsModule = {
  namespaced: true,
  state: {
    data: [],
    loaded: false
  },
  mutations: {
    set (state, insights) {
      state.data = insights
    },
    modify (state, { type, data }) {
      const index = state.data.findIndex(el => el.type === type)
      if (data.type) {
        Object.assign(state.data[index], data)
      } else {
        state.data.splice(index, 1)
      }
    }
  },
  actions: {
    async load ({ state, dispatch }) {
      if (!state.loaded) {
        dispatch('reload')
      }
    },
    async reload ({ commit, rootState }) {
      const insights = await rootState.dataSource.get('Insights', { cache: false })
      commit('set', insights)
    },
    async refresh ({ commit }, insight) {
      const response = await axios.get(`${awsBaseUrl}/${insight.func}`)
      commit('modify', { type: insight.type, data: response.data })
    }
  }
}

export default insightsModule
