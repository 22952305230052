import Ably from 'ably/callbacks'
import { v1 as uuidv1 } from 'uuid'

import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue2'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import MoodlePlugin from '@/plugins/moodle'
import axios from 'axios'

const awsExternalApiV2 = process.env.VUE_APP_AWS_EXTERNAL_API_V2

Vue.config.productionTip = false

Vue.use(CKEditor)
Vue.use(MoodlePlugin)

Vue.mixin({
  methods: {
    showWarning (message) {
      this.$store.commit('setWarningMessage', message)
    },
    showInfo (message) {
      this.$store.commit('setInfoMessage', message)
    },
    blockInteractions (message) {
      this.$store.commit('setOverlay', { progress: 0, message })
    },
    setProgress (value, message) {
      this.$store.commit('setOverlay', { progress: value, message })
    },
    unblockInteractions () {
      this.$store.commit('setOverlay', undefined)
    },
    canRead (section) {
      return this.$store.state.acl[section] > 0
    },
    canWrite (section) {
      return this.$store.state.acl[section] > 1
    },
    sendEmail (message) {
      return axios.post(`${awsExternalApiV2}/email`, {
        secret: this.$store.state.mailSecret,
        message: message
      })
    }
  }
})

const vm = new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
})

let dashboardId = localStorage.getItem('dashboard_id')
if (!dashboardId) {
  dashboardId = uuidv1()
  localStorage.setItem('dashboard_id', dashboardId)
}
store.commit('setDashboardId', dashboardId)

const ablyKey = process.env.VUE_APP_ABLY_KEY

const ably = new Ably.Realtime(ablyKey)
const privateChannel = ably.channels.get(dashboardId)
privateChannel.subscribe('progress', function (message) {
  const data = JSON.parse(message.data)
  store.commit('setOverlay', { progress: data.progress, message: data.message })
})
privateChannel.subscribe('info', function (message) {
  vm.showInfo(message.data)
})
const publicChannel = ably.channels.get('public')
publicChannel.subscribe('submission', function (message) {
  console.log(message.data)
})
publicChannel.subscribe('regtable', function (message) {
  console.log(message.data)
})
publicChannel.subscribe('cache', function (message) {
  const { dashboardId, cacheKey, operation, data } = JSON.parse(message.data)
  console.log('cache event', dashboardId, cacheKey, operation, data, store.state.dashboardId)
  if (dashboardId !== store.state.dashboardId) {
    const cacheKey1 = cacheKey || data
    if (!cacheKey1.includes('@')) {
      const collection = cacheKey1.toLowerCase()
      if (operation === 'reload') {
        store.dispatch(`${collection}/reload`)
      } else if (operation === 'update') {
        store.commit(`${collection}/modify`, data)
      } else if (operation === 'delete') {
        store.commit(`${collection}/delete`, data)
      } else {
        console.warn(`Unknown operation ${operation}`)
      }
    }
  } else {
    console.log('Ignoring cache event, the same dashboard')
  }
})

vm.$mount('#app')
