<template>
  <v-list-item
    :class="itemClass"
    class="text-subtitle"
    dense
    @click="go"
  >
    <v-list-item-action class="my-0 mr-4">
      <font-awesome-icon
        :icon="icon"
        fixed-width
        size="lg"
        color="grey"
      />
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>{{ text }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'NavigationItem',
  props: {
    target: String,
    icon: Array,
    text: String
  },
  computed: {
    itemClass () {
      if (this.target === '') {
        return this.$router.currentRoute.path === '/' ? 'v-list-item--active' : ''
      } else {
        return this.$router.currentRoute.path.startsWith(`/${this.target}`) ? 'v-list-item--active' : ''
      }
    }
  },
  methods: {
    go () {
      const to = `/${this.target}`
      if (this.$router.currentRoute.path !== to) {
        this.$router.push(to)
      }
    }
  }
}
</script>
