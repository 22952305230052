<template>
  <v-app-bar
    app
    color="indigo"
    dark
  >
    <v-app-bar-nav-icon @click.stop="$emit('toggle-drawer')">
      <v-icon>$vuetify.icons.menu</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title>STQA Dashboard</v-toolbar-title>
    <v-spacer />
    <v-banner
      v-if="refreshToUpdateVersion"
      single-line
      tile
    >
      Появилась новая версия дашборда, обновите страничку
    </v-banner>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppBar',
  props: {
    refreshToUpdateVersion: Boolean
  },
  computed: mapState({
    user: state => state.user
  })
}
</script>
