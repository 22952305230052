const coursesModule = {
  namespaced: true,
  state: {
    loaded: false,
    data: []
  },
  getters: {
    byUUID: state => uuid => state.data.find(el => el.uuid === uuid)
  },
  mutations: {
    loaded (state, value) {
      state.loaded = value
    },
    set (state, courses) {
      state.data = courses
    },
    add (state, course) {
      state.data.push(course)
    },
    modify (state, course) {
      const index = state.data.findIndex(el => el.uuid === course.uuid)
      if (index >= 0) {
        Object.assign(state.data[index], course)
      } else {
        state.data.push(course)
      }
    },
    delete (state, uuid) {
      const index = state.data.findIndex(el => el.uuid === uuid)
      state.data.splice(index, 1)
    }
  },
  actions: {
    async load ({ state, dispatch }) {
      if (!state.loaded) {
        dispatch('reload')
      }
    },
    async reload ({ commit, rootState }) {
      commit('loaded', false)
      commit('set', [])
      const courses = await rootState.dataSource2.get({ collection: 'Courses' })
      commit('set', courses)
      commit('loaded', true)
    },
    async create ({ commit, rootState }, course) {
      const created = await rootState.dataSource2.create({ collection: 'Courses', data: course })
      commit('add', created)
    },
    async update ({ commit, rootState }, course) {
      const updated = await rootState.dataSource2.update({ collection: 'Courses', data: course })
      commit('modify', updated)
    },
    async delete ({ commit, rootState }, uuid) {
      await rootState.dataSource2.delete({ collection: 'Courses', key: uuid })
      commit('delete', uuid)
    }
  }
}

export default coursesModule
