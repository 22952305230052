const settingsModule = {
  namespaced: true,
  state: {
    data: []
  },
  mutations: {
    set (state, settings) {
      state.data = settings
    },
    add (state, setting) {
      state.data.push(setting)
    },
    modify (state, setting) {
      const index = state.data.findIndex(el => el.uuid === setting.uuid)
      Object.assign(state.data[index], setting)
    },
    delete (state, setting) {
      const index = state.data.findIndex(el => el.uuid === setting.uuid)
      state.data.splice(index, 1)
    }
  },
  getters: {
    byKey: state => key => {
      const found = state.data.find(el => el.key === key)
      return found ? found.value : ''
    }
  },
  actions: {
    async load ({ state, commit, rootState }) {
      if (state.data.length === 0) {
        const settings = await rootState.dataSource2.get({ collection: 'Settings' })
        commit('set', settings)
      }
    },
    async create ({ commit, rootState }, setting) {
      const created = await rootState.dataSource2.create({ collection: 'Settings', data: setting })
      commit('add', created)
    },
    async update ({ commit, rootState }, setting) {
      const updated = await rootState.dataSource2.update({ collection: 'Settings', data: setting })
      commit('modify', updated)
    },
    async delete ({ commit, rootState }, setting) {
      await rootState.dataSource2.delete({ collection: 'Settings', key: setting.uuid })
      commit('delete', setting)
    }
  }
}

export default settingsModule
