import Vue from 'vue'
const axios = require('axios')

const apiBaseUrl = process.env.VUE_APP_AWS_BASE_URL

const contractsModule = {
  namespaced: true,
  state: {
    loaded: false,
    data: [],
    loadedForCustomer: {},
    dataByCustomer: {}
  },
  getters: {
    byUUID: state => uuid => state.data.find(el => el.uuid === uuid),
    loadedForCustomer: state => customerUuid => state.loadedForCustomer[customerUuid],
    byCustomerUUID: state => customerUuid => state.data.filter(el => el.customer === customerUuid)
  },
  mutations: {
    loaded (state, value) {
      state.loaded = value
    },
    loadedForCustomer (state, { customerUuid, value }) {
      Vue.set(state.loadedForCustomer, customerUuid, value)
    },
    set (state, contracts) {
      state.data = contracts
    },
    add (state, contract) {
      state.data.unshift(contract)
    },
    modify (state, contract) {
      const index = state.data.findIndex(el => el.uuid === contract.uuid)
      if (index >= 0) {
        Object.assign(state.data[index], contract)
      }
    },
    delete (state, uuid) {
      const index = state.data.findIndex(el => el.uuid === uuid)
      state.data.splice(index, 1)
    },
    deleteDocLink (state, { contract, docLink }) {
      const index = state.data.findIndex(el => el.uuid === contract.uuid)
      if (index >= 0) {
        const docIndex = state.data[index].documents.indexOf(docLink)
        state.data[index].documents.splice(docIndex, 1)
      }
    },
    deleteAttachment (state, { contract, attachment }) {
      const index = state.data.findIndex(el => el.uuid === contract.uuid)
      if (index >= 0) {
        const docIndex = state.data[index].attachments.findIndex(el => el.title === attachment.title)
        state.data[index].attachments.splice(docIndex, 1)
      }
    }
  },
  actions: {
    async load ({ state, dispatch }) {
      if (!state.loaded) {
        dispatch('reload')
      }
    },
    async loadForCustomer ({ state, dispatch }, customerUuid) {
      if (!state.loadedForCustomer[customerUuid]) {
        dispatch('reloadForCustomer', customerUuid)
      }
    },
    async reload ({ commit, rootState }) {
      commit('loaded', false)
      commit('set', [])
      console.log('reload contracts')
      // const contracts = await rootState.dataSource2.getPartOf({ collection: 'Contracts', opts: { size: 100, reverse: true } })
      const contracts = await rootState.dataSource2.getPartOf({ collection: 'Contracts', opts: { size: 100, reverse: true } })
      contracts.sort((b, a) => (a.ts > b.ts) ? 1 : ((b.ts > a.ts) ? -1 : 0))
      commit('set', contracts)
      commit('loaded', true)
    },
    async reloadForCustomer ({ commit, state, rootState }, customerUuid) {
      commit('loadedForCustomer', { customerUuid, value: false })
      const filteredData = state.data.filter(el => el.customer !== customerUuid)
      commit('set', filteredData)
      const contracts = await rootState.dataSource2.getPartOf({ collection: 'Contracts', key: 'customer', value: customerUuid })
      const newData = [...filteredData, ...contracts]
      newData.sort((b, a) => (a.ts > b.ts) ? 1 : ((b.ts > a.ts) ? -1 : 0))
      commit('set', newData)
      commit('loadedForCustomer', { customerUuid, value: true })
    },
    async create ({ commit, state, rootState, dispatch }, contract) {
      const created = await rootState.dataSource2.create({ collection: 'Contracts', data: contract })
      const folderResponse = await axios.get(`${apiBaseUrl}/contractfolder/${created.uuid}`)
      created.docsFolder = folderResponse.data
      const updated = await rootState.dataSource2.update({ collection: 'Contracts', data: created })
      commit('add', updated)
      if (state.loadedForCustomer[contract.customer]) {
        dispatch('reloadForCustomer', contract.customer)
      }
    },
    async update ({ commit, state, rootState, dispatch }, contract) {
      if (!contract.docsFolder) {
        const folderResponse = await axios.get(`${apiBaseUrl}/contractfolder/${contract.uuid}`)
        contract.docsFolder = folderResponse.data
      }
      const updated = await rootState.dataSource2.update({ collection: 'Contracts', data: contract })
      commit('modify', updated)
    },
    async delete ({ commit, state, rootState, dispatch }, uuid) {
      await rootState.dataSource2.delete({ collection: 'Contracts', key: uuid })
      commit('delete', uuid)
    },
    async deleteDocLink ({ commit, rootState }, params) {
      const contract = params.contract
      const docLink = params.docLink
      const updatedDocuments = contract.documents
      updatedDocuments.splice(updatedDocuments.indexOf(docLink), 1)
      await rootState.dataSource2.update({ collection: 'Contracts', data: { uuid: contract.uuid, documents: updatedDocuments } })
      commit('deleteDocLink', params)
    },
    async deleteAttachment ({ commit, rootState }, params) {
      const contract = params.contract
      const attachment = params.attachment
      const updatedAttachments = contract.attachments || []
      updatedAttachments.splice(updatedAttachments.findIndex(el => el.title === attachment.title), 1)
      await rootState.dataSource2.update({ collection: 'Contracts', data: { uuid: contract.uuid, attachments: updatedAttachments } })
      commit('deleteAttachment', params)
    }
  }
}

export default contractsModule
