const fragmentsModule = {
  namespaced: true,
  state: {
    data: []
  },
  mutations: {
    set (state, fragments) {
      state.data = fragments
    },
    add (state, fragment) {
      state.data.push(fragment)
    },
    modify (state, fragment) {
      const index = state.data.findIndex(el => el.uuid === fragment.uuid)
      Object.assign(state.data[index], fragment)
    },
    delete (state, fragment) {
      const index = state.data.findIndex(el => el.uuid === fragment.uuid)
      state.data.splice(index, 1)
    }
  },
  getters: {
    byKey: state => key => {
      return state.data.find(el => el.key === key).value
    }
  },
  actions: {
    async load ({ state, commit, rootState }) {
      if (state.data.length === 0) {
        const fragments = await rootState.dataSource2.get({ collection: 'Fragments' })
        commit('set', fragments)
      }
    },
    async create ({ commit, rootState }, fragment) {
      const created = await rootState.dataSource2.create({ collection: 'Fragments', data: fragment })
      commit('add', created)
    },
    async update ({ commit, rootState }, fragment) {
      const updated = await rootState.dataSource2.update({ collection: 'Fragments', data: fragment })
      commit('modify', updated)
    },
    async delete ({ commit, rootState }, fragment) {
      await rootState.dataSource2.delete({ collection: 'Fragments', key: fragment.uuid })
      commit('delete', fragment)
    }
  }
}

export default fragmentsModule
