const PAGE_SIZE = 10

const registrationsModule = {
  namespaced: true,
  state: {
    data: [],
    cursor: undefined
  },
  mutations: {
    set (state, registrations) {
      state.data = registrations
    },
    setCursor (state, cursor) {
      state.cursor = cursor
    }
  },
  actions: {
    async load ({ state, dispatch }) {
      if (state.data.length === 0) {
        return dispatch('reload')
      } else {
        return !!state.cursor
      }
    },
    async reload ({ commit, rootState }) {
      const { data: submissions, cursor } = await rootState.dataSource.getPage('Submissions', { size: PAGE_SIZE, reverse: true })
      commit('set', submissions)
      commit('setCursor', cursor)
      return !!cursor
    },
    async loadMore ({ commit, state, rootState }) {
      const { data: submissions, cursor } = await rootState.dataSource.getPage('Submissions', { size: PAGE_SIZE, reverse: true, cursor: state.cursor })
      commit('set', [...state.data, ...submissions])
      commit('setCursor', cursor)
      return !!cursor
    }
  }
}

export default registrationsModule
