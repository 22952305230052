import Vue from 'vue'

const eventsModule = {
  namespaced: true,
  state: {
    data: {}
  },
  mutations: {
    set (state, { courseUUID, events }) {
      Vue.set(state.data, courseUUID, events)
    },
    add (state, event) {
      const courseUUID = event.course
      if (state.data[courseUUID] === undefined) {
        Vue.set(state.data, courseUUID, [])
      }
      state.data[courseUUID].push(event)
    },
    modify (state, event) {
      const courseUUID = event.course
      const events = state.data[courseUUID]
      if (events) {
        const index = events.findIndex(el => el.uuid === event.uuid)
        Object.assign(events[index], event)
      }
    },
    delete (state, event) {
      const courseUUID = event.course
      const events = state.data[courseUUID]
      if (events) {
        const index = events.findIndex(el => el.uuid === event.uuid)
        events.splice(index, 1)
      }
    }
  },
  getters: {
    byCourseUUID: state => courseUUID => {
      return state.data[courseUUID] || []
    }
  },
  actions: {
    async load ({ state, dispatch }, courseUUID) {
      if (!state.data[courseUUID]) {
        await dispatch('reload', courseUUID)
      }
    },
    async reload ({ commit, rootState }, courseUUID) {
      const events = await rootState.dataSource2.getPartOf({ collection: 'Events', key: 'course', value: courseUUID })
      commit('set', { courseUUID, events })
    },
    async create ({ commit, rootState }, event) {
      const created = await rootState.dataSource2.create({ collection: 'Events', data: event })
      commit('add', created)
    },
    async multiCreate ({ commit, dispatch, rootState }, { courseUUID, events }) {
      const created = await rootState.dataSource2.create({ collection: 'Events', data: events })
      commit('set', { courseUUID, events: created })
    },
    async update ({ commit, rootState }, event) {
      const updated = await rootState.dataSource2.update({ collection: 'Events', data: event })
      commit('modify', updated)
    },
    async delete ({ commit, rootState }, event) {
      await rootState.dataSource2.delete({ collection: 'Events', key: event.uuid })
      commit('delete', event)
    },
    async clear ({ commit, rootState }, courseUUID) {
      await rootState.dataSource2.deletePartOf({ collection: 'Events', key: 'course', value: courseUUID })
      commit('set', { courseUUID, events: [] })
    }
  }
}

export default eventsModule
