import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faFilePdf, faFileWord, faPen } from '@fortawesome/pro-solid-svg-icons'
import {
  faAngleDown, faAngleUp,
  faBars, faBell, faBuilding,
  faClipboardCheck, faCloud, faCode, faCogs, faCommentAltLines, faCompressArrowsAlt, faCopy,
  faEnvelope, faExclamationTriangle, faExpandArrows,
  faCertificate, faFilter, faFolders,
  faGlobe, faGraduationCap,
  faHome,
  faPenNib, faPlus,
  faReceipt,
  faShippingFast, faSignOut, faSyncAlt,
  faTimes, faTrash,
  faUnlink, faUsdCircle, faUser, faUsers, faUserGraduate
} from '@fortawesome/pro-regular-svg-icons'
import { faCalendar, faCalendarDays, faTableList } from '@fortawesome/pro-light-svg-icons'
import { faGoogleDrive, faSkype } from '@fortawesome/free-brands-svg-icons'

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
library.add(
  faAngleDown, faAngleUp,
  faBars, faBell, faBuilding,
  faCalendar, faCalendarDays, faClipboardCheck, faCloud, faCode, faCogs, faCommentAltLines, faCompressArrowsAlt, faCopy,
  faEnvelope, faExclamationTriangle, faExpandArrows,
  faCertificate, faFilePdf, faFileWord, faFilter, faFolders,
  faHome,
  faGlobe, faGoogleDrive, faGraduationCap,
  faPen, faPenNib, faPlus,
  faReceipt,
  faShippingFast, faSignOut, faSkype, faSyncAlt,
  faTableList, faTimes, faTrash,
  faUnlink, faUsdCircle, faUser, faUsers, faUserGraduate
)

Vue.use(Vuetify)

function iconFromSet (set, icon) {
  return {
    component: FontAwesomeIcon,
    props: {
      icon: [set, icon]
    }
  }
}

function solidIcon (icon) {
  return iconFromSet('fas', icon)
}

function regularIcon (icon) {
  return iconFromSet('far', icon)
}

function lightIcon (icon) {
  return iconFromSet('fal', icon)
}

function brandIcon (icon) {
  return iconFromSet('fab', icon)
}

// function duotoneIcon (icon) {
//  return iconFromSet('fad', icon)
// }

export default new Vuetify({
  icons: {
    values: {
      add: regularIcon('plus'),
      alert: regularIcon('exclamation-triangle'),
      angleDown: regularIcon('angle-down'),
      angleUp: regularIcon('angle-up'),
      calendar: regularIcon('calendar-days'),
      close: regularIcon('times'),
      cloud: regularIcon('cloud'),
      code: regularIcon('code'),
      collapse: regularIcon('compress-arrows-alt'),
      comment: regularIcon('comment-alt-lines'),
      copy: regularIcon('copy'),
      delete: regularIcon('times'),
      edit: solidIcon('pen'),
      email: regularIcon('envelope'),
      expand: regularIcon('expand-arrows'),
      filter: regularIcon('filter'),
      googleDrive: brandIcon('google-drive'),
      lms: regularIcon('graduation-cap'),
      lmsConnection: regularIcon('bell'),
      menu: regularIcon('bars'),
      msword: solidIcon('file-word'),
      payment: regularIcon('usd-circle'),
      pdf: solidIcon('file-pdf'),
      reload: regularIcon('sync-alt'),
      rules: regularIcon('clipboard-check'),
      signer: regularIcon('pen-nib'),
      signOut: regularIcon('sign-out'),
      skype: brandIcon('skype'),
      table: lightIcon('table-list'),
      teacher: regularIcon('user-graduate'),
      trash: regularIcon('trash'),
      unlink: regularIcon('unlink'),
      website: regularIcon('globe')
    }
  }
})
