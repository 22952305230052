import { Moodle } from '@stqa/datasource'

const MoodlePlugin = {
  install: function (Vue) {
    Vue.prototype.$moodle = function () {
      return {
        get: (wsfunction, params) => {
          return new Moodle(this.$store.state.moodleToken).get(wsfunction, params)
        },
        post: (wsfunction, params) => {
          return new Moodle(this.$store.state.moodleToken).post(wsfunction, params)
        }
      }
    }
  }
}

export default MoodlePlugin
