import { Moodle } from '@stqa/datasource'

async function loadCoursesInCategory (category, moodleToken) {
  const moodle = new Moodle(moodleToken)
  const result = await moodle.get('core_course_get_courses_by_field', { field: 'category', value: category })
  return result.courses.map(course => {
    course.id = '' + course.id
    course.categoryid = '' + course.categoryid
    return course
  })
}

const lmsModule = {
  namespaced: true,
  state: {
    templates: [],
    courses: []
  },
  mutations: {
    setTemplates (state, templates) {
      state.templates = templates
    },
    setCourses (state, courses) {
      state.courses = courses
    }
  },
  actions: {
    async reloadTemplates ({ state, dispatch }) {
      if (state.templates.length === 0) {
        dispatch('loadTemplates')
      }
    },
    async loadTemplates ({ commit, rootState }) {
      commit('setTemplates', await loadCoursesInCategory('56', rootState.moodleToken))
    },
    async loadCourses ({ state, dispatch }) {
      if (state.courses.length === 0) {
        await dispatch('reloadCourses')
      }
    },
    async reloadCourses ({ commit, rootState }) {
      const active = await loadCoursesInCategory('25', rootState.moodleToken)
      const startingSoon = await loadCoursesInCategory('27', rootState.moodleToken)
      commit('setCourses', [...active, ...startingSoon])
    }
  }
}

export default lmsModule
